@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Roboto+Slab:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Round|Material+Icons+Outlined');
.material-icons-round.md-14 { font-size: 14px; }
.material-icons-round.md-16 { font-size: 16px; }
.material-icons-round.md-18 { font-size: 18px; }
.material-icons-round.md-24 { font-size: 24px; vertical-align: middle;}
.material-icons-round.md-28 { font-size: 28px; }
.material-icons-round.md-32 { font-size: 32px; }
.material-icons-round.md-36 { font-size: 36px; }
.material-icons-round.md-48 { font-size: 48px; }
.material-icons-outlined.md-14 { font-size: 14px; }
.material-icons-outlined.md-16 { font-size: 16px; }
.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-20 { font-size: 20px; }
.material-icons-outlined.md-22 { font-size: 22px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-28 { font-size: 28px; }
.material-icons-outlined.md-32 { font-size: 32px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }

.App {
  text-align: center;
  min-height: 100vh;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1{
  font-size: 48px;
  font-weight: 900;
}

a{
  color: #2E86DE;
}


.pageButtons{
  cursor: pointer;
}

.pageNumber{
  color: #2E86DE;
  font-size: 20px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 600;
  vertical-align: middle;
}

.App-link {
  color: #61dafb;
}

.logo{
  width: 75%;
  max-width: 100px;
}

.menuButton{
  float: right;
  margin: 25px 10px;
  vertical-align: bottom;
}

.signInButton{
  /* position: absolute;
  top: 50px;
  right: 25px; */
  float: right;
  margin: 25px 10px;
  vertical-align: bottom;
}

@media screen and (max-width: 550px) {
  .signInButton{
    float: left;
    margin: 27px 5px;
    vertical-align: bottom;
  }
  .menuButton{
    float: left;
    margin: 25px 5px;
    vertical-align: bottom;
  }
}

.zeroPadMar{
  margin: 0px;
  padding: 0px;
}

header{
  padding-top:15px;
  padding-bottom:25px;
  margin: 0 auto;
  text-align: center;
  background-color: whitesmoke;
  /* clip-path: polygon(100% 0, 100% 60%, 50% 100%, 0 60%, 0 0); */
  clip-path: polygon(0 0, 100% 0, 100% 74%, 0% 100%);

}

.bodyHeight{
  min-height: 80vh;
}

.ActionBar{
  height: 60px;
  background-color: whitesmoke;
}

.LandingImage{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.LandingImageDiv{
  width: 80%;
  max-width: 500px;
  max-height: 500px;
  margin: 0 auto;
}

/*test*/
.Grid {
  margin-top: 75px;
  margin-left: 40px;
  margin-right: 40px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: center;
  grid-gap: 30px;
  
}

.FlexGrid {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: center;
  grid-gap: 30px;
  flex-wrap: wrap;  
}



.GridItem {
  width:100%;
  max-width: 300px;
  height: 375px;
  cursor: pointer;
  background-color: white;
  margin: auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 2px 10px -5px;
  transition: 0.3s;
}

.priceDiv{
  background-color: transparent;
  margin: 0 auto;
  max-width: 900px;
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
}
.plainItem {
  height: 95px;
}
.priceItem {
  height: 250px;
  max-width: 250px;
}
.priceItem h1 {
  font-size: 22px;
}
.priceItem p {
  font-size: 14px;
}
.priceText {
  font-size: 42px;
  font-weight: bolder;
}

.priceItem button{
  background-color: #2E86DE;
  color: white;
  border: 3px solid #2E86DE;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px -5px;
}

.topBorderBlue{
  border-top: 6px solid #2E86DE;
}
.topBorderGrey{
  border-top: 6px solid lightgray;
}

.priceItem button:hover{
  background-color:whitesmoke;
  border: 3px solid #2E86DE;
  color:#2E86DE;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px -5px;
}

.GridItem:hover {
  box-shadow: 0px 5px 15px -5px;
}
.GridImageContainer {
  height: 66%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.GridImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  position: center center;
  vertical-align: middle;
}
h2.GridItemTitle {
  color: black;
  font-size: calc(16px);
  font-family: Roboto Slab, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  width: 92%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
}
h2.GridItemPrice {
  color: white;
  font-size: calc(22px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 700;
  min-width: 40%;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 10px -20px;
  padding: 8px 12px;
  background-color: #2E86DE;
  border-radius: 15px 0px 0px 15px;
  text-align: left;
  position: absolute;
  top: 8px;
  right: 16px;
}
p.GridItemDescription {
  color: black;
  font-size: calc(12px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;

  margin: 10px 10px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
}

footer{
  max-width: 100vw;
  text-align: left;
  background-color: whitesmoke;
  padding: 15px;
  line-height: 1.5em;
  padding-left: 10vw;
  padding-right: 10vw;
  margin: 0px;
}

.artIcon{
  color: whitesmoke;
  text-align: center;
  font-size: 175px;
}

.legal {
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

p.thin{
  text-align: center;
  background-color: transparent;
  line-height: 1.5em;
  color: black;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.footerBrand {
  width:130px;
  height: 130px;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
}

h2.footer {
  color: black;
  font-size: 18px;
  font-family: Comfortaa, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 700;
}

p.footer {
  color: black;
  font-size: 16px;
  font-family: Comfortaa, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 400;
}
.footerInfo {
  display: inline-block;
  position: relative;
  margin-left: 25px;
  vertical-align: top;
  text-align: left;
}

@media screen and (max-width: 750px) {
  footer{
    text-align: center;
    background-color: whitesmoke;
    padding: 15px;
    line-height: 1.5em;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .footerInfo {
    text-align: center;
    margin-left: 0px;
  }

  header{
    /* clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0 0); */
    clip-path: polygon(0 0, 100% 0, 100% 74%, 0% 100%);

  }
}

.lightLine{
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: gray;
  padding: 0px;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.powered {
  color: grey;
  font-size: 13px;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.textCenter{
  text-align: center;
}

.comingSoon{
  height: 60vh;
  text-align: center;
}

.detailView{
  padding-top: 5vw;
  margin: 0 auto;
  text-align: center;
}

.DetailItemTitle {
  color: black;
  font-size: calc(32px);
  font-family: Roboto Slab, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  word-wrap: normal;
  margin: 10px 10px;
  text-align: left;
  vertical-align: middle;
}
.CityTitle {
  color: #8395a7;
  font-size: calc(22px);
  font-family: Roboto Slab, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
  vertical-align: middle;
  line-height: 58px;
  float: right;
}
.DetailItemPrice {
  color: white;
  font-size: calc(32px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 700;
  width: 25%;
  min-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
  float:right;
  margin: 10px 0px;
  padding: 10px 15px;
  background-color: #2E86DE;
  border-radius: 20px 0px 0px 20px;
  vertical-align: middle;
}
.DetailItemDescription {
  color: black;
  font-size: calc(18px);
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
  width: 92%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  margin: 10px 10px;
  text-align: left;
}
.DetailInfoBox{
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 80px;
  width: 90%;
  max-width: 1000px;
}



.galleryContainer{
  width:90%;
  max-width: 900px;
  margin: 0 auto;
}

.titleDiv{
  vertical-align: middle;
  margin-bottom: 90px;
}

.clearDiv{
  display:block;
  clear:both;
}

@media screen and (max-width: 750px) {
  .galleryContainer{
    width:100%;
    margin: 0 auto;
  }
}

.FeatureGrid {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;
  grid-gap: 20px;
  background-color: whitesmoke;
  border-radius: 15px;
  border: 1px solid #2E86DE;
  overflow:hidden;
  box-shadow: 0px 2px 10px -5px;
}

.FeatureGridItem {
  width:100%;
  max-width: 100%;
  padding: 0px;
  background-color: transparent;
  margin: 0px;
  overflow:hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.FeatureItem{
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.featureIcon{
  color: #2E86DE;
  vertical-align: middle;
}

.bottomAlignIcon{
  color: #2E86DE;
  vertical-align: text-top;
}

.featureText{
  vertical-align: middle;
  font-family: Comfortaa, Futura, Trebuchet MS, Arial, sans-serif;
  font-size: 32;
  font-weight: 700;
  text-overflow: ellipsis;
}

.landingContent{
  margin: 0 auto;
  width: 80%;
  max-width: 700px;
}
.mainButtonIcon{
  vertical-align: middle;
}

.HighlightFeatureList{
  float: left;
}

.StripeElement {
  box-sizing: border-box;
  max-width: 600px;
  height: 40px;
  margin : 0 auto;
  margin-bottom: 20px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #989a9c;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripeCustField {
  width: 100%;
  max-width: 600px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 #b3b6b9;
  -webkit-appearance: none;
}
.label {
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 600px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}
.labelRed {
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 600px;
  color: red;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}

.redtext{
  color: red;
}

.DescTextarea {
  height: 250px;
  font-family: Roboto, Futura, Trebuchet MS, Arial, sans-serif;
}

.checkbox{
  margin: 10px;
	display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease;
}

.mainButton {
  text-transform: uppercase;
  background-color: #2E86DE;
  border: 3px solid transparent;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
}

.mainButton:disabled {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}
.mainButton:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}

.mainButton:hover{
  background-color:transparent;
  border: 3px solid white;
  color:white;
  transition: all 0.3s ease;
}

.mainButtonContrast {
  text-transform: uppercase;
  background-color: #2E86DE;
  border: 3px solid transparent;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
  z-index: 10;
}

.mainButtonContrast:hover{
  background-color:transparent;
  border: 3px solid #2E86DE;
  color:#2E86DE;
  transition: all 0.3s ease;
}

.mainButtonContrastRed{
  background-color: #ff6b6b !important;
}
.mainButtonContrastRed:hover{
  background-color:transparent !important;
  border: 3px solid #ff6b6b !important;
  color: #ff6b6b !important;
}
.mainButtonContrastRed:disabled {
  background-color:whitesmoke !important;
  border: 3px solid #c8d6e5 !important;
  color: #c8d6e5 !important;
}
.mainButtonContrastRed:disabled:hover {
  background-color:whitesmoke !important;
  border: 3px solid #c8d6e5 !important;
  color: #c8d6e5 !important;
}

.mainButtonContrast:disabled {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}
.mainButtonContrast:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}


.secondaryButton {
  text-transform: uppercase;
  background-color: rgba(0, 0, 0,0.4);
  border: 3px solid white;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
}

.secondaryButton:disabled {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}
.secondaryButton:disabled:hover {
  background-color:whitesmoke;
  border: 2px solid #c8d6e5;
  color: #c8d6e5;
}

.secondaryButton:hover{
  background-color:black;
  border: 3px solid black;
  color: white;
  transition: all 0.3s ease;
}

.lineItem {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  padding: 0px 22px 0px 22px;
  width: 90%;
  max-width: 600px;
  height: 60px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
}
.lineItemLong {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  padding: 20px 22px 20px 22px;
  width: 90%;
  max-width: 1000px;
  
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
}


.lineImage{
  height: 100%;
  width: 125px;
  overflow: hidden;
  object-fit: cover;
  vertical-align: middle;
  margin: 0px;
  margin-right: 20px;
}
.lineItem:hover {
  box-shadow: 0px 2px 8px -5px black;
  transition: all 0.3s ease;
}
.lineItemSelected {
  border: 2px solid #2E86DE;
  background-color: white;
  box-shadow: 0px 2px 8px -5px black;
}

p.lineItemText {
  float: left;
  margin: 0 auto;
  margin-top: 5px;
  white-space: pre-wrap;
}
.lineItemTextDisabled {
  opacity: 0.6;
}
.lineItemSub {
  font-size: calc(15px);
  float: right;
  color: rgb(52, 142, 216);
  vertical-align: middle;
}

.lineButton{
  opacity: 0.60;
  border: 0px;
  background-color: transparent;
  color: #2E86DE;
  float: right;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}
.lineIcon{
  opacity: 1.0;
  border: 0px;
  background-color: transparent;
  color: #2E86DE;
  float: left;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.lineButton:hover{
  opacity: 1;
  transition: all 0.3s ease;
}

p.lineItemTextColumn {
  float: left;
  margin: 0px 20px;
  font-weight: 500;
  white-space: pre-wrap;
}
.lineItemColumnHead {
  font-weight: 300;
}

.AlignExport{
  text-align: right;
  width: 90%;
  margin: 0 auto;
  max-width: 1000px;
}

@media screen and (max-width: 750px) {
  .lineItem {
    height: 120px;
    width: 80%;
  }
  .lineItemLong{
    width: 80%;
  }
  p.lineItemText {
    width: 100%;
  }
  .lineButton{
    float: left;
    height: 60%;
  }
  p.lineItemTextColumn {
    float: left;
    width: 100%;
    margin: 20px 20px;
    font-weight: 500;
    white-space: pre-wrap;
  }
  .AlignExport{
    text-align: center;
    width: 80%;
  }
  .filterBar{
    width: 80%;
  
  }
}



.spacer{
  width: 100%;
  height: 25px;
  margin: 0;
  padding: 0;
}

.menuItemDetail-back {
  text-align: center;
  position: fixed; /* Stay in place */
  z-index: 10000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.25); /* Black w/ opacity */
}

.loaderOverlay {
  width: 140px;
  background-color: white;
  padding: 20px;
  margin: 20% auto; /* 15% from the top and centered */
  border-radius: 10px;
}

.formContainer textarea{
  height: 150px;
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.authCard{
  text-align: center;
  padding: 50px;
}

.authCard h2{
  text-transform: uppercase;
  color:#000826;
  font-size: 18px;
}

.Badge {
  background-color: #1dd1a1;
  border-radius: 8px;
  color: white;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 10px;
  font-size: 18px;
  font-family: Roboto, Helvetica Neue, sans-serif;
  transition: all 0.3s ease;
  vertical-align: middle;
}

.SoldBadge {
  background-color: #ff6b6b !important;
}

.optionContainer{
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
}

.optionLine {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px 32px;
  margin: 0px 10px 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
  float: left;
}
.optionLine:hover {
  box-shadow: 0px 2px 8px -5px black;
  transition: all 0.3s ease;
}
.optionLineSelected {
  border: 2px solid #2E86DE;
  background-color: white;
  box-shadow: 0px 2px 8px -5px black;
}

p.optionText {
  float: left;
}
p.optionPrice {
  font-size: calc(15px);
  float: right;
  color: #2E86DE;
}


.filterBar{
  text-align: left;
  width: 90%;
  margin: 0 auto;
  max-width: 1000px;

}

.bubbleButton {
  font-size: calc(16px);
  font-family: Roboto, Helvetica Neue, sans-serif;
  color: black;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 10px 32px;
  margin: 0px 10px 10px;
  box-shadow: 0px 2px 5px -5px black;
  transition: all 0.3s ease;
  float: left;
}
.bubbleButton:hover {
  box-shadow: 0px 2px 8px -5px black;
  transition: all 0.3s ease;
}
.bubbleSelected {
  border: 2px solid #2E86DE;
  background-color: white;
  box-shadow: 0px 2px 8px -5px black;
}

.linkButton{
  background-color: transparent;
  border: 0;
  /* text-decoration: underline; */
  color: #2E86DE;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 18px;
  font-family: Comfortaa, Futura, Trebuchet MS, Arial, sans-serif;
  font-weight: 800;
}
.linkButton:hover{
  transition: all 0.3s ease;
  opacity: 0.6;
}
.linkButton:disabled {
  color: #c8d6e5;
}
.linkButton:disabled:hover {
  color: #c8d6e5;
}




.barButton{
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: #2E86DE;
  float: left;
  transition: all 0.3s ease;
  cursor: pointer;
  vertical-align: bottom;
  width: 44px;
  opacity: 1.0;
}
.barButton:hover{
  transition: all 0.3s ease;
  opacity: 0.6;
}

.favButton{
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
}
.favButton:hover{
  transition: all 0.3s ease;
  opacity: 0.6;
}

.favButtonGridContainer{
  position: absolute;
  top: 20px;
  left: 10px;
}

.closeButton {
  background-color: transparent;
  color: #2E86DE;
  border: 0px;
  opacity: 0.6;
  cursor: pointer;
  position:absolute;
  right: 20px;
  top: 20px;
  transition: all 0.3s ease;
}
.closeButton:hover{
  opacity: 1.0;
  transition: all 0.3s ease;
}


.modal-back {
  position: fixed; /* Stay in place */
  text-align: center;
  z-index: 1000000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.25); /* Black w/ opacity */
}
.modal-back-print {
  position: fixed; /* Stay in place */
  text-align: center;
  z-index: 1000000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: white;
}
.modal-content {
  background-color: white;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  box-shadow: 0px 2px 10px -5px;
  border-radius: 15px;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 500px;
  position: relative;
  display: inline-block;
  animation: open 0.25s ease-out;
}

.navMenu{
  position: fixed; /* Stay in place */
  text-align: center;
  z-index: 1000000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 168, 255);
  background-color: rgba(0, 168, 255, 0.95);
}
.navBtnBox{
  position: absolute;
  top: 90px;
  left: 15px;
  right: 15px;
  text-align: left;
}

.navLightLine{
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: whitesmoke;
  padding: 0px;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  opacity: 0.6;
}

.nothingDiv{
  margin: 0;
  padding: 0;
}

.leftCornerMenuMain{
  position: absolute;
  top: 25px;
  left: 15px;
}

.leftCornerMenu{
  background-color: white;
  border: none;
  border: 3px solid transparent;
  border-radius: 10px;
  color: #2E86DE;
  padding: 8px 8px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 14px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  -webkit-appearance:none;
}

.leftCornerMenu:disabled {
  box-shadow: 0;
}
.leftCornerMenu:disabled:hover {
  box-shadow: 0;
}

.leftCornerMenu:hover{
  background-color:whitesmoke;
  border: 3px solid transparent;
  box-shadow: 0px 2px 10px -5px;
  color:black;
  transition: all 0.3s ease;
}


.Navbar {
  background-color: rgba(255.0, 255.0, 255.0, 0.0);
  position: absolute; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  left: 0;
  width: 100%; /* Full width */
  height: 150px;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  z-index: 1000000;
  transition: all 0.3s ease;

  overflow: visible;
}
.NavSpacer{
  height: 150px;
  width: 100%;
  background-color: white;
}

.NavContents{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  height: 100%;
  overflow: visible;
}

.NavbarBrand {
  height: 120px;
  float: left;
  transition: all 0.3s ease;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: transparent;
  display: block;
  overflow: visible;
  padding-top: 40px;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: right;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: transparent;
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Add an active class to highlight the current page */

.topnav a.active {
  background-color: transparent;
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.topnav a.dark {
  background-color: black;
  color: white;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
  transition: all 0.3s ease;
} 

.dropdown {
  float: right;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease;
}



/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.dropdown:hover .dropbtn{
  background-color: #f9f9f9;
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background to dropdown links on hover */
.dropdown-content a:hover {
  color: #B5251C;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}


 /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 750px) {
  .topnav a , .dropdown .dropbtn {display: none;}
  .topnav a.icon {
    float: right;
    padding: 5px 7px;
    margin-bottom: 10px;
    display: block;
    transition: none;
  }
  .NavbarBrand {
    height: 75px;
    float: left;
    transition: all 0.3s ease;
  }
  .topnav {
      background-color: transparent;
      display: block;
      overflow: hidden;
      padding-top: 30px;
    }
}


/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 750px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive a.icon {
    transition: all 0.3s ease;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: right;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
  .topnav.responsive .dropdown {float: none;}
.topnav.responsive .dropdown-content {position: relative;}
.topnav.responsive .dropdown .dropbtn {
  display: block;
  width: 100%;
  text-align: right;
}
  .Navbar.responsive {
      overflow: hidden;
      background-color: rgba(255.0, 255.0, 255.0, 0.95);
      position: absolute; /* Set the navbar to fixed position */
      top: 0; /* Position the navbar at the top of the page */
      left: 0;
      width: 100%; /* Full width */
      height: 200%;
      padding: 10px;
      text-align: center;
      box-sizing: border-box;
      z-index: 1000000;
      transition: all 0.3s ease;
    }
  .NavbarBrand.responsive {
      display: none;
      transition: all 0.3s ease;
  }
} 


.dateTimeButton {
  background-color: whitesmoke;
  border: 3px solid transparent;
  border-radius: 10px;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 10px;
  cursor: pointer;
  font-size: 18px;
  height: auto;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  -webkit-appearance:none;
}
select{
  text-align: center;
  text-align-last:center;
  text-align: -webkit-center;
}

.dateTimeButton:disabled {
  box-shadow: 0;
}
.dateTimeButton:disabled:hover {
  box-shadow: 0;
}

.dateTimeButton:hover{
  background-color:whitesmoke;
  border: 3px solid transparent;
  box-shadow: 0px 2px 10px -5px;
  color:black;
  transition: all 0.3s ease;
}


.inlineArrowButton{
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: #2E86DE;
  transition: all 0.3s ease;
  cursor: pointer;
  vertical-align: middle;
  width: 44px;
  opacity: 1.0;
}
.inlineArrowButton:hover{
  transition: all 0.3s ease;
  opacity: 0.6;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    .modal-content{
      background-color: white;
      margin: 15% auto; /* 15% from the top and centered */
      padding: 20px;
      box-shadow: 0px 0px 0px 0px;
      border-radius: 15px;
      width: 80%; /* Could be more or less, depending on screen size */
      max-width: 500px;
      position: relative;
      display: inline-block;
    }

    footer{
      display: none !important;
    }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes open {
  0% {
      transform: scale(0.05);
  }
  100% {
    transform: scale(1);
}
}
